import {httpGet, httpPost, httpPut, httpDelete} from '@/plugins/request'



/**
 * 提成列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetTcIndex(query = [])
{
    return httpGet('/manage/tc/rule', query)
}

/**
 * 新增提成
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateTc(data)
{
    return httpPost('/manage/tc/rule', data)
}

/**
 * 新增提成
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateTc(id, data)
{
    return httpPut(`/manage/tc/rule/${id}`, data)
}

/**
 * 新增提成
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiDestroyTc(id)
{
    return httpDelete(`/manage/tc/rule/${id}`)
}

/**
 * 提成人列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetTcPersonIndex(query = [])
{
    return httpGet('/manage/tc/person', query)
}

/**
 * 新增提成人
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateTcPerson(data)
{
    return httpPost('/manage/tc/person', data)
}

/**
 * 修改提成人
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateTcPerson(id, data)
{
    return httpPut(`/manage/tc/person/${id}`, data)
}


/**
 * 获取提成明细
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetTcDetailIndex(query = [])
{
    return httpGet('/manage/tc/detail', query)
}

/**
 * 新增详情
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateTcDetail(data)
{
    return httpPost('/manage/tc/detail', data)
}

/**
 * 获取提成明细
 *
 * @returns {Promise<*>}
 * @param id
 * @param data
 */
export function apiUpdateTcDetail(id, data = [])
{
    return httpPut(`/manage/tc/detail/${id}`, data)
}

/**
 * 获取提成明细
 *
 * @returns {Promise<*>}
 * @param id
 */
export function apiDestroyTcDetail(id)
{
    return httpDelete(`/manage/tc/detail/${id}`)
}

/**
 * 获取提成明细
 *
 * @returns {Promise<*>}
 * @param query
 */
export function apiCompleteTcDetail(query = {})
{
    return httpPost(`/manage/tc/detail/batch/complete`, query)
}
